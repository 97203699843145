@import '../variables';

.sort {
  position: relative;
	user-select: none;

	@media(max-width: 1380px) {
		margin-bottom: 30px;
	}

	&.active {
		svg {
			transform: rotate(0deg);
		}
	}

  &__label {
    display: flex;
    align-items: center;

    svg {
      margin-right: 8px;
			transform: rotate(180deg);
			transition: transform .3s ease-in-out;
    }

    b {
      margin-right: 8px;

			@media (max-width: 380px) {
				display: none;
			}
    }

    span {
      color: $orange;
      border-bottom: 1px dashed $orange;
      cursor: pointer;
    }
  }

  &__popup {
    position: absolute;
    right: 0;
    margin-top: 15px;
    background: #ffffff;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.09);
    border-radius: 10px;
    overflow: hidden;
    padding: 10px 0;

    ul {
      overflow: hidden;
      li {
        padding: 12px 20px;
        cursor: pointer;

        &.active,
        &:hover {
          background: rgba(254, 95, 30, 0.05);
        }

        &.active {
          font-weight: bold;
          color: $orange;
        }
      }
    }
  }
}
