@import 'variables';
@import 'libs/normalize';

@import 'components/all';

body {
  background-color: $background;
}

.wrapper {
  width: calc(100vw - 100px);
  height: 100%;
  background-color: #fff;
  margin: 50px auto;
  border-radius: 10px;
  max-width: 1400px;

	@media (max-width: 1068px) {
		width: calc(100vw - 50px);
		margin: 25px auto;
	}

	@media (max-width: 699.99px) {
		width: calc(100vw - 30px);
		margin: 15px auto;
	}
}

.content {
  padding: 40px 0;

	&__error-info {
		text-align: center;
		margin: 60px auto;
		max-width: 580px;
		width: 100%;

		h3 {
			font-size: 32px;
			font-weight: 700;
			margin-bottom: 10px;
		}

		p {
			font-size: 20px;
			color: #8d8d8d;
		}
	}

  &__title {
		margin-top: 35px;
		margin-bottom: 50px;
		font-size: 38px;
		font-weight: 700;
  }

  &__items {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		grid-column-gap: 25px;
		justify-items: center;

		@media (max-width: 1400px) {
			grid-template-columns: repeat(3, 1fr);
		}

		@media (max-width: 1068px) {
			grid-template-columns: repeat(2, 1fr);
		}

		@media (max-width: 699.99px) {
			grid-template-columns: 1fr;
		}
  }

  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;

		@media (max-width: 1380px) {
			flex-direction: column-reverse;
			align-items: flex-start;
		}
  }
}

.container {
  width: $container-width;
  margin: 0 auto;

  &--cart {
    max-width: 820px;
    margin: 90px auto;
    .content__title {
      margin: 0;
    }
  }
}

.cart {
  &__top {
    display: flex;
		flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

		h2 {

		}
  }

	.content__items {
		display: block;
	}

  .content__title {
    display: flex;
    align-items: center;
		font-size: 32px;

    svg {
      position: relative;
      top: -2px;
      width: 30px;
      height: 30px;
      margin-right: 10px;
      path {
        stroke: $black;
        stroke-width: 1.9;
      }
    }
  }

  &__clear {
    display: flex;
    align-items: center;
    cursor: pointer;
    @include noselect();

    span {
      display: inline-block;
      margin-left: 7px;
      color: #b6b6b6;
      font-size: 18px;
    }

    span,
    svg,
    path {
      transition: all $duration ease-in-out;
    }

    &:hover {
      svg {
        path {
          stroke: darken($color: #b6b6b6, $amount: 50);
        }
      }
      span {
        color: darken($color: #b6b6b6, $amount: 50);
      }
    }
  }

  &__item {
    display: flex;
    width: 100%;
    border-top: 1px solid $gray-line;
    padding-top: 30px;
    margin-top: 30px;

    &-img {
      display: flex;
      align-items: center;
      margin-right: 15px;
      width: 10%;

      img {
        width: 80px;
        height: 80px;
      }
    }

    &-info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 40%;

      h3 {
        font-weight: bold;
        font-size: 22px;
        line-height: 27px;
        letter-spacing: 0.01em;
      }

      p {
        font-size: 18px;
        color: #8d8d8d;
      }
    }

    &-count {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 13%;

      &-minus {
        svg {
          path:first-of-type {
            display: none;
          }
        }
      }

      b {
        font-size: 22px;
      }
    }

    &-price {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 33%;

      b {
        font-weight: bold;
        font-size: 22px;
        letter-spacing: 0.01em;
      }
    }

    &-remove {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 4%;

      .button {
        border-color: darken($color: $gray-line, $amount: 10);
      }

      svg {
        transform: rotate(45deg);

        path {
          fill: darken($color: $gray-line, $amount: 15);
        }
      }

      .button {
        svg {
          width: 11.5px;
          height: 11.5px;
          position: relative;
        }
        &:hover,
        &:active {
          border-color: darken($color: $gray-line, $amount: 80);
          background-color: darken($color: $gray-line, $amount: 80);
        }
      }
    }
  }

  &__bottom {
    margin: 50px 0;

    &-details {
      display: flex;
      justify-content: space-between;

      span {
        font-size: 22px;

        &:last-of-type {
          b {
            color: $orange;
          }
        }
      }
    }

    &-buttons {
      display: flex;
      justify-content: space-between;
      margin-top: 40px;

      .go-back-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 210px;

        border-color: darken($color: $gray-line, $amount: 10);

        span {
          color: darken($color: $gray-line, $amount: 20);
          font-weight: 500;
          font-weight: 600;
        }

        &:hover {
          background-color: darken($color: $gray-line, $amount: 90);
          border-color: darken($color: $gray-line, $amount: 90);

          span {
            color: $gray-line;
          }
        }

        svg {
          margin-right: 12px;
          path {
            fill: transparent;
            stroke-width: 2;
          }
        }
      }

      .pay-btn {
        font-size: 16px;
        font-weight: 600;
        width: 210px;
        padding: 16px;
      }
    }
  }

  &--empty {
    margin: 0 auto;
    max-width: 560px;
    text-align: center;

    h2 {
      font-size: 32px;
      margin-bottom: 15px;

			span {
				position: relative;
				display: inline-block;
				top: 2px;

				@media(max-width: 380px) {
					margin-top: 10px;
				}
			}
    }

    p {
      font-size: 18px;
      line-height: 145.4%;
      letter-spacing: 0.01em;
      color: #777777;
    }

    img {
      display: block;
      max-width: 300px;
			width: 100%;
      margin: 45px auto 60px;
    }
  }
}
