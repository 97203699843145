.root {
	position: relative;

	@media (max-width: 767.99px) {
		margin-bottom: 15px;
	}
}

.input {
	border: 1px solid rgba(0, 0, 0, .1);
	padding: 12px 42px;
	width: 100%;
	max-width: 300px;
	border-radius: 10px;
	font-size: 16px;
	transition: all .3s ease-in-out;

	&:focus {
		border: 2px solid rgba(0, 0, 0, .1);
	}
}

.icon {
	width: 24px;
	height: 24px;
	opacity: .3;
	position: absolute;
	left: 14px;
	top: 50%;
	transform: translateY(-50%);
}

.clearIcon {
	width: 18px;
	height: 18px;
	opacity: .3;
	position: absolute;
	right: 15px;
	top: 50%;
	transform: translateY(-50%);
	cursor: pointer;
	transition: opacity .3s ease-in-out;

	&:hover {
		opacity: .8;
	}
}