.root {
	text-align: center;
	padding: 100px 15px;
	max-width: 750px;
	margin: 0 auto;

	h1 {
		span {
			font-size: 64px;
		}
	}
}

.descr {
	font-size: 24px;
	margin-bottom: 20px;
}

