@import '../variables';

.header {
  border-bottom: 1px solid $gray-line;
  padding: 40px 0;

  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;

		@media (max-width: 767.99px) {
			flex-direction: column;
			justify-content: center;
		}
  }

	&__text {
		@media (max-width: 955.99px) {
			display: none;
		}

		@media (max-width: 767.99px) {
			display: block;
			text-align: center;
		}
	}

  &__logo {
    display: flex;

		@media (max-width: 767.99px) {
			flex-direction: column;
			justify-content: center;
			align-items: center;
			margin-bottom: 15px;
		}

    img {
      margin-right: 15px;

			@media (max-width: 767.99px) {
				margin-right: 0;
				margin-bottom: 15px;
			}
    }

    h1 {
      color: #181818;
      font-size: 24px;
      letter-spacing: 1%;
      text-transform: uppercase;
      font-weight: 800;
    }

    p {
      color: #7b7b7b;
    }
  }
}
