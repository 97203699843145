.paginate {
	:global {

		li.selected {
			a {
				background-color: #fe5f1e;
				color: #fff;
			}
		}

		.next a,
		.previous a {
			background-color: #fe5f1e;
			color: #fff;
		}

		.disabled a{
			color: #b6b6b6;
			border-color: #b6b6b6;
			background-color: transparent;

			&:hover {
				color: #b6b6b6;
				border-color: #b6b6b6;
				background-color: transparent;
			}
		}
	}

	li {
		display: inline-block;

		&:not(:last-child) {
			margin-right: 10px;
		}

		a {
			color: #fe5f1e;
			font-weight: 700;
			display: inline-flex;
			justify-content: center;
			align-items: center;
			height: 40px;
			width: 40px;
			height: 40px;
			padding: 10px;
			background-color: transparent;
			border: 1px solid #fe5f1e;
			border-radius: 100%;
			cursor: pointer;

			&:hover {
				background-color: #fe5f1e;
				color: #fff;
			}
		}
	}

}